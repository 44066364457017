import { toast } from "react-toastify";
import SuccessAlertIcon from "../Icons/SuccessAlertIcon";
import WarningAlertIcon from "../Icons/WarningAlertIcon";

const lastErrorTimestamp: { [key: string]: number } = {};

export const successToast = (msg: string) =>
  toast.success(msg, {
    icon: (
      <span className="text-primary-main">
        <SuccessAlertIcon />
      </span>
    ),
  });

export const warningToast = (msg: string) => toast.warning(msg);

export const infoToast = (msg: string) => toast.info(msg);

// export const errorToast = (msg: string, isAuthicationErrorShow?: Boolean) => {
//   console.log("msg: ", msg);
//   let isMuteError = false;
//   if (msg === "Error in creating BMS Device" && isMuteError) {
//     isMuteError = true;
//     return;
//   }
//   return toast.error(msg, {
//     icon: (
//       <span className="text-[#F87171]">
//         <WarningAlertIcon />
//       </span>
//     ),
//   });
// };

export const errorToast = (msg: string) => {
  const currentTime = Date.now();
  const muteDuration = 1000; // 1 second

  // Check if the same error occurred recently (within the last 1 second)
  if (lastErrorTimestamp[msg] && currentTime - lastErrorTimestamp[msg] < muteDuration) {
    return false; // If error happened in the last second, mute it
  }

  // Set or update the timestamp for the current error message
  lastErrorTimestamp[msg] = currentTime;

  // Now, always return the toast.error call
  return toast.error(msg, {
    icon: (
      <span className="text-[#F87171]">
        <WarningAlertIcon />
      </span>
    ),
  });
};
