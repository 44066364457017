/* eslint class-methods-use-this: "off" */
import axios from "axios";
import { getToken } from "../../utils/general";
import { API_PERMISSION_PATHS, PERMISSION_ACTIONS } from "../../utils/status";
import { interceptorFunction } from "../interceptors";
const interceptor = interceptorFunction();
export const login = (data: any) => interceptor.post(`/account/login`, data);

export const updateTermsAndConditions = (data: any) =>
  interceptor.patch("/terms-and-conditions", data);

export const changePassword = (data: any) =>
  interceptor.post(`/account/change-password`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const getProfile = () =>
  interceptor.get(`/account/profile`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.PROFILE.GET]: PERMISSION_ACTIONS.READ,
      }),
    },
  });

export const updateProfile = (data: any) =>
  interceptor.patch(`/account/profile`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.PROFILE.UPDATE]: PERMISSION_ACTIONS.READ,
      }),
    },
  });

export const verifyEmail = (data: any) =>
  interceptor.patch(`/account/email-verify`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const resendVerifcationMail = (data: any) =>
  interceptor.patch(`/resend-verification-email`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const verifyOTP = (data: any) =>
  interceptor.post(`/verifyotp`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const skipNumberVerification = (data: { login_id: string }) =>
  interceptor.patch(`/account/number-verification`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const updateCompanyLogo = (data: any) =>
  interceptor.put(`/account/profile`, data, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.PROFILE.UPDATE]: PERMISSION_ACTIONS.READ,
      }),
    },
  });

export const getAPIKey = (email: string) =>
  interceptor.post(
    `/account/get-secret-token`,
    { email },
    {
      headers: {
        authorization: `Bearer ${getToken()}`,
        "x-permission-value": JSON.stringify({
          [API_PERMISSION_PATHS.PROFILE.REST_API]: PERMISSION_ACTIONS.READ,
        }),
      },
    },
  );

export const generateAPIKey = (email: string) =>
  interceptor.post(
    `/account/generate-secret-token`,
    { email },
    {
      headers: {
        authorization: `Bearer ${getToken()}`,
        "x-permission-value": JSON.stringify({
          [API_PERMISSION_PATHS.PROFILE.REST_API]: PERMISSION_ACTIONS.READ,
        }),
      },
    },
  );

export const getSwaggerJson = () =>
  axios.get(`${process.env.NEXT_PUBLIC_SWAGGER_API_URL}/api-doc`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
      "x-permission-value": JSON.stringify({
        [API_PERMISSION_PATHS.PROFILE.REST_API]: PERMISSION_ACTIONS.READ,
      }),
    },
  });
